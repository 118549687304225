var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"reveal"},[_c('div',{staticClass:"slides"},[_vm._m(0),_vm._m(1),_vm._m(2),_c('section',[_c('div',{attrs:{"align":"center"}},[_c('video',{attrs:{"data-autoplay":"","src":_vm.baseuri+'/LAVANDERIA_MQ.mp4',"controls":"","preload":"metadata","controlsList":"nodownload"}})])]),_vm._m(3),_vm._m(4),_vm._m(5),_vm._m(6),_vm._m(7),_vm._m(8),_vm._m(9),_vm._m(10),_vm._m(11),_c('section',[_c('div',{attrs:{"align":"center"}},[_c('video',{attrs:{"data-autoplay":"","src":_vm.baseuri+'/PASSADORIA_MQ.mp4',"controls":"","preload":"metadata","controlsList":"nodownload"}})])]),_vm._m(12),_vm._m(13),_vm._m(14),_vm._m(15),_vm._m(16),_vm._m(17)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('section',[_c('img',{attrs:{"src":require("@/assets/img/module-laundry/Slide1.png"),"width":"100%","height":"auto"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('section',[_c('img',{attrs:{"src":require("@/assets/img/module-laundry/Slide2.png"),"width":"100%","height":"auto"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('section',[_c('img',{attrs:{"src":require("@/assets/img/module-laundry/Slide3.png"),"width":"100%","height":"auto"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('section',[_c('img',{attrs:{"src":require("@/assets/img/module-laundry/Slide4.png"),"width":"100%","height":"auto"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('section',[_c('img',{attrs:{"src":require("@/assets/img/module-laundry/Slide5.png"),"width":"100%","height":"auto"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('section',[_c('img',{attrs:{"src":require("@/assets/img/module-laundry/Slide6.png"),"width":"100%","height":"auto"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('section',[_c('img',{attrs:{"src":require("@/assets/img/module-laundry/Slide7.png"),"width":"100%","height":"auto"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('section',[_c('img',{attrs:{"src":require("@/assets/img/module-laundry/Slide8.png"),"width":"100%","height":"auto"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('section',[_c('img',{attrs:{"src":require("@/assets/img/module-laundry/Slide9.png"),"width":"100%","height":"auto"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('section',[_c('img',{attrs:{"src":require("@/assets/img/module-laundry/Slide10.png"),"width":"100%","height":"auto"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('section',[_c('img',{attrs:{"src":require("@/assets/img/module-laundry/Slide11.png"),"width":"100%","height":"auto"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('section',[_c('img',{attrs:{"src":require("@/assets/img/module-laundry/Slide12.png"),"width":"100%","height":"auto"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('section',[_c('img',{attrs:{"src":require("@/assets/img/module-laundry/Slide13.png"),"width":"100%","height":"auto"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('section',[_c('img',{attrs:{"src":require("@/assets/img/module-laundry/Slide14.png"),"width":"100%","height":"auto"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('section',[_c('img',{attrs:{"src":require("@/assets/img/module-laundry/Slide15.png"),"width":"100%","height":"auto"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('section',[_c('img',{attrs:{"src":require("@/assets/img/module-laundry/Slide16.png"),"width":"100%","height":"auto"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('section',[_c('img',{attrs:{"src":require("@/assets/img/module-laundry/Slide17.png"),"width":"100%","height":"auto"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('section',[_c('img',{attrs:{"src":require("@/assets/img/module-laundry/Slide18.png"),"width":"100%","height":"auto"}})])
}]

export { render, staticRenderFns }