<template>
    <div class="reveal">
      <div class="slides">
		<section><img src="@/assets/img/module-basic/Slide1.png" width="100%" height="auto"></section>
		<section><img src="@/assets/img/module-basic/Slide2.png" width="100%" height="auto"></section>
		<section>
			<div align="center">
				<video data-autoplay :src="baseuri+'/Boss Talk_MQ.mp4'" controls preload="metadata" controlsList="nodownload"></video>
			</div>
		</section>
		<section><img src="@/assets/img/module-basic/Slide3.png" width="100%" height="auto"></section>
		<section><img src="@/assets/img/module-basic/Slide4.png" width="100%" height="auto"></section>
		<section><img src="@/assets/img/module-basic/Slide5.png" width="100%" height="auto"></section>
		<section><img src="@/assets/img/module-basic/Slide6.png" width="100%" height="auto"></section>
		<section><img src="@/assets/img/module-basic/Slide7.png" width="100%" height="auto"></section>
		<section><img src="@/assets/img/module-basic/Slide8.png" width="100%" height="auto"></section>
		<section><img src="@/assets/img/module-basic/Slide9.png" width="100%" height="auto"></section>
		<section>
			<div align="center">
				<video data-autoplay :src="baseuri+'/Modulo Basico Mary Help - Contratacao da diarista_MQ.mp4'" controls preload="metadata" controlsList="nodownload"></video>
			</div>
		</section>
		<section><img src="@/assets/img/module-basic/Slide10.png" width="100%" height="auto"></section>
		<section><img src="@/assets/img/module-basic/Slide11.png" width="100%" height="auto"></section>
		<section><img src="@/assets/img/module-basic/Slide12.png" width="100%" height="auto"></section>
        <section style="position: relative; height: 100%;">
			<div align="center" style="position: absolute; height: 100%; top: 0px; right: 50%; transform: translateX(50%);">
				<video data-autoplay :src="baseuri+'/Modulo+Basico+Mary+Help+-+App+DMH+Agenda.mp4'" controls preload="metadata" controlsList="nodownload"></video>
			</div>
		</section>
		<section><img src="@/assets/img/module-basic/Slide13.png" width="100%" height="auto"></section>
		<section><img src="@/assets/img/module-basic/Slide14.png" width="100%" height="auto"></section>
		<section><img src="@/assets/img/module-basic/Slide15.png" width="100%" height="auto"></section>
		<section>
			<div align="center">
				<video data-autoplay :src="baseuri+'/Novo - Modulo Basico Mary Help - Chegada ao trabalho_MQ.mp4'" controls preload="metadata" controlsList="nodownload"></video>
			</div>
		</section>
		<section><img src="@/assets/img/module-basic/Slide16.png" width="100%" height="auto"></section>
		<section><img src="@/assets/img/module-basic/Slide17.png" width="100%" height="auto"></section>
		<section><img src="@/assets/img/module-basic/Slide18.png" width="100%" height="auto"></section>
		<section><img src="@/assets/img/module-basic/Slide19.png" width="100%" height="auto"></section>
		<section><img src="@/assets/img/module-basic/Slide20.png" width="100%" height="auto"></section>
		<section><img src="@/assets/img/module-basic/Slide21.png" width="100%" height="auto"></section>
		<section>
			<div align="center">
				<video data-autoplay :src="baseuri+'/Modulo Basico Mary Help - Postura no trabalho_MQ.mp4'" controls preload="metadata" controlsList="nodownload"></video>
			</div>
		</section>
		<section><img src="@/assets/img/module-basic/Slide22.png" width="100%" height="auto"></section>
		<section><img src="@/assets/img/module-basic/Slide23.png" width="100%" height="auto"></section>
		<section><img src="@/assets/img/module-basic/Slide24.png" width="100%" height="auto"></section>
		<section><img src="@/assets/img/module-basic/Slide25.png" width="100%" height="auto"></section>
		<section><img src="@/assets/img/module-basic/Slide26.png" width="100%" height="auto"></section>
		<section><img src="@/assets/img/module-basic/Slide27.png" width="100%" height="auto"></section>
		<section><img src="@/assets/img/module-basic/Slide28.png" width="100%" height="auto"></section>
		<section><img src="@/assets/img/module-basic/Slide29.png" width="100%" height="auto"></section>
		<section><img src="@/assets/img/module-basic/Slide30.png" width="100%" height="auto"></section>
		<section><img src="@/assets/img/module-basic/Slide31.png" width="100%" height="auto"></section>
		<section><img src="@/assets/img/module-basic/Slide32.png" width="100%" height="auto"></section>
		<section><img src="@/assets/img/module-basic/Slide33.png" width="100%" height="auto"></section>
		<section>
			<div align="center">
				<video data-autoplay :src="baseuri+'/Modulo Basico Mary Help - Terminando o expediente_MQ.mp4'" controls preload="metadata" controlsList="nodownload"></video>
			</div>
		</section>
		<section><img src="@/assets/img/module-basic/Slide34.png" width="100%" height="auto"></section>
		<section><img src="@/assets/img/module-basic/Slide35.png" width="100%" height="auto"></section>
		<section><img src="@/assets/img/module-basic/Slide36.png" width="100%" height="auto"></section>
		<section><img src="@/assets/img/module-basic/Slide37.png" width="100%" height="auto"></section>
		<section><img src="@/assets/img/module-basic/Slide38.png" width="100%" height="auto"></section>
		<section><img src="@/assets/img/module-basic/Slide39.png" width="100%" height="auto"></section>
		<section><img src="@/assets/img/module-basic/Slide40.png" width="100%" height="auto"></section>
		<section><img src="@/assets/img/module-basic/Slide41.png" width="100%" height="auto"></section>
		<section><img src="@/assets/img/module-basic/Slide42.png" width="100%" height="auto"></section>
		<section><img src="@/assets/img/module-basic/Slide43.png" width="100%" height="auto"></section>
		<section><img src="@/assets/img/module-basic/Slide44.png" width="100%" height="auto"></section>
		<section><img src="@/assets/img/module-basic/Slide45.png" width="100%" height="auto"></section>
		<section><img src="@/assets/img/module-basic/Slide46.png" width="100%" height="auto"></section>
		<section><img src="@/assets/img/module-basic/Slide47.png" width="100%" height="auto"></section>
		<section><img src="@/assets/img/module-basic/Slide48.png" width="100%" height="auto"></section>
		<section><img src="@/assets/img/module-basic/Slide49.png" width="100%" height="auto"></section>
		<section><img src="@/assets/img/module-basic/Slide50.png" width="100%" height="auto"></section>
		<section><img src="@/assets/img/module-basic/Slide51.png" width="100%" height="auto"></section>
      </div>
    </div>
</template>

<script>
import Reveal from 'reveal.js/js/reveal';
import Markdown from 'reveal.js/plugin/markdown/markdown.esm.js';

export default {
    data() {
		return {
			baseuri: 'https://d1fieeylnsubh1.cloudfront.net/module_attachments'
		}
	},
	mounted() {
        var reveal = new Reveal({
            plugins: [ Markdown ]
        });
        reveal.initialize();
    }
};
</script>

<style>
    @import url('../../../node_modules/reveal.js/dist/reveal.css');
    @import url('../../../node_modules/reveal.js/dist/theme/black.css');
    
    .reveal-viewport #mary-help-treinamentos {
      width: 100vw;
      height: 100vh;
      min-height: initial !important;
      background-color: transparent;
    }

	.slides > section {
        top: 0% !important;
    }
</style>