<template>
    <div class="reveal">
        <div class="slides">
            <section><img src="@/assets/img/module-clean/Slide1.png" width="100%" height="auto"></section>
            <section><img src="@/assets/img/module-clean/Slide2.png" width="100%" height="auto"></section>
            <section><img src="@/assets/img/module-clean/Slide3.png" width="100%" height="auto"></section>
            <section><img src="@/assets/img/module-clean/Slide4.png" width="100%" height="auto"></section>
            <section><img src="@/assets/img/module-clean/Slide5.png" width="100%" height="auto"></section>
            <section><img src="@/assets/img/module-clean/Slide6.png" width="100%" height="auto"></section>
            <section><img src="@/assets/img/module-clean/Slide7.png" width="100%" height="auto"></section>
            <section><img src="@/assets/img/module-clean/Slide9.png" width="100%" height="auto"></section>
            <section>
                <video  data-autoplay class="video-js" controls preload="metadata" controlsList="nodownload">
                    <source :src="baseuri+'/ORGANIZACAO E PLANEJAMENTO_MQ.mp4'" type='video/mp4' >
                </video>
            </section>
            <section><img src="@/assets/img/module-clean/Slide10.png" width="100%" height="auto"></section>
            <section><img src="@/assets/img/module-clean/Slide11.png" width="100%" height="auto"></section>
            <section><img src="@/assets/img/module-clean/Slide12.png" width="100%" height="auto"></section>
            <section><img src="@/assets/img/module-clean/Slide13.png" width="100%" height="auto"></section>
            <section>
                <video  data-autoplay class="video-js" controls preload="metadata" controlsList="nodownload">
                    <source :src="baseuri+'/LIMPEZA DE QUARTOS E BANHEIROS_MQ.mp4'" type='video/mp4' >
                </video>
            </section>
            <section><img src="@/assets/img/module-clean/Slide14.png" width="100%" height="auto"></section>
            <section><img src="@/assets/img/module-clean/Slide15.png" width="100%" height="auto"></section>
            <section><img src="@/assets/img/module-clean/Slide16.png" width="100%" height="auto"></section>
            <section>
                <video  data-autoplay class="video-js" controls preload="metadata" controlsList="nodownload">
                    <source :src="baseuri+'/LIMPEZA DE SALAS_MQ.mp4'" type='video/mp4' >
                </video>
            </section>
            <section><img src="@/assets/img/module-clean/Slide17.png" width="100%" height="auto"></section>
            <section><img src="@/assets/img/module-clean/Slide18.png" width="100%" height="auto"></section>
            <section><img src="@/assets/img/module-clean/Slide19.png" width="100%" height="auto"></section>
            <section>
                <video  data-autoplay class="video-js" controls preload="metadata" controlsList="nodownload">
                    <source :src="baseuri+'/LIMPEZA DE COZINHAS_MQ.mp4'" type='video/mp4' >
                </video>
            </section>
            <section><img src="@/assets/img/module-clean/Slide20.png" width="100%" height="auto"></section>
            <section><img src="@/assets/img/module-clean/Slide21.png" width="100%" height="auto"></section>
            <section><img src="@/assets/img/module-clean/Slide22.png" width="100%" height="auto"></section>
            <section><img src="@/assets/img/module-clean/Slide23.png" width="100%" height="auto"></section>
            <section>
                <video  data-autoplay class="video-js" controls preload="metadata" controlsList="nodownload">
                    <source :src="baseuri+'/LIMPEZA DE METAIS_MQ.mp4'" type='video/mp4' >
                </video>
            </section>
            <section><img src="@/assets/img/module-clean/Slide24.png" width="100%" height="auto"></section>
            <section>
                <video  data-autoplay class="video-js" controls preload="metadata" controlsList="nodownload">
                    <source :src="baseuri+'/ERROS COMUNS PARA SEREM EVITADOS_MQ.mp4'" type='video/mp4' >
                </video>
            </section>
            <section><img src="@/assets/img/module-clean/Slide25.png" width="100%" height="auto"></section>
            <section><img src="@/assets/img/module-clean/Slide26.png" width="100%" height="auto"></section>
            <section><img src="@/assets/img/module-clean/Slide27.png" width="100%" height="auto"></section>
            <section>
                <video  data-autoplay class="video-js" controls preload="metadata" controlsList="nodownload">
                    <source :src="baseuri+'/TERMINANDO O DIA_MQ.mp4'" type='video/mp4' >
                </video>
            </section>
            <section><img src="@/assets/img/module-clean/Slide28.png" width="100%" height="auto"></section>
        </div>
    </div>
</template>

<script>
import Reveal from 'reveal.js/js/reveal';
import Markdown from 'reveal.js/plugin/markdown/markdown.esm.js';

export default {
    data() {
        return {
            baseuri: 'https://d1fieeylnsubh1.cloudfront.net/module_attachments'
        }
    },
    mounted() {
        var reveal = new Reveal({
            plugins: [ Markdown ]
        });
        reveal.initialize();
    }
};
</script>

<style>
    @import url('../../../node_modules/reveal.js/dist/reveal.css');
    @import url('../../../node_modules/reveal.js/dist/theme/black.css');
    
    .reveal-viewport #mary-help-treinamentos {
      width: 100vw;
      height: 100vh;
      min-height: initial !important;
      background-color: transparent;
    }

    .slides > section {
        top: 0% !important;
    }
</style>