<template>
    <div class="reveal" v-if="pdf_show">
        <div class="slides">
            <section><pdf :key="pdf_show" :src="pdf_show" @num-pages="pdf_pages = $event" :page="1" @page-loaded="PDFLoaded"></pdf></section>
            <template v-if="pdf_show && pdf_pages && pdf_pages > 0">
                <section v-for="i in (pdf_pages - 1)" :key="i"><pdf :key="pdf_show+i" :src="pdf_show"  :page="i + 1" @page-loaded="PDFLoaded"></pdf></section>
            </template>
        </div>
    </div>
    <b-overlay v-else show rounded="sm">
        <div class="loading"></div>
    </b-overlay>
</template>

<script>
import Reveal from 'reveal.js/js/reveal';
import Markdown from 'reveal.js/plugin/markdown/markdown.esm.js';
import pdf from 'vue-pdf'

export default {
    components: {
        pdf
    },
    data() {
        return {
            id_module: this.$route.params.id_module,
            id_file: this.$route.params.id_file,
            pdf_show: null,
            pdf_pages: 0,
            pdf_loaded: false
        }
    },
    mounted() {
        this.LoadData();
    },
    methods: {
        LoadData() {
            this.pdf_show = null;
            this.pdf_pages = 0;
            this.$bvModal.show('pdf-view');
            this.pdf_loaded = false;
            this.$store.dispatch('module/getPdf', {
                module_id: this.id_module, 
                file_id: this.id_file
            }).then(data => {
                this.pdf_loaded = true;
                this.pdf_show = URL.createObjectURL(data);
            }).catch(() => {
                this.pdf_show = null;
            });
        },
        PDFLoaded(page) {
            if (page == this.pdf_pages) {
                var reveal = new Reveal({
                    plugins: [ Markdown ]
                });
                reveal.initialize();
            }
        }
    }
};
</script>

<style>
    @import url('../../../node_modules/reveal.js/dist/reveal.css');
    @import url('../../../node_modules/reveal.js/dist/theme/black.css');
    
    .reveal-viewport #mary-help-treinamentos {
      width: 100vw;
      height: 100vh;
      min-height: initial !important;
      background-color: transparent;
    }
</style>

<style scoped>
    .slides > section {
        top: 0% !important;
        overflow: scroll!important;
        height: 100%!important;
    }

    .loading {
        width: 100vw;
        height: 100vh;
    }
</style>